const translations = {
    'hu': {
        nivo: 'Építészeti Nivódíj 2017: Érd DM Iroda Központ',
        navAbout:  'Rólunk',
        navServices: 'Szolgáltatásaink',
        navPortfolio: 'Referenciák',
        navContacts: 'Kapcsolat',
        'txt-intro': 'Beruházáslebonyolítás - Generáltervezés - Főépítésvezetés - Műszaki Ellenőrzés',
        'header-about-us': 'Rólunk',
        'about-p1': 'Az Intermanagement Iroda Kft. 1993-ben alakult, immár 100%-ban magyar tulajdonban levő mérnöki vállalkozás. Irodánk megalakulása óta nyújt komplex építőipari mérnöki szolgáltatásokat, amelyek lefedik a magasépítési projekt teljes életciklusát, a beruházási ötlet felmerülésétől egészen a kulcsrakész átadásig.',
        'about-p2': 'Az Intermanagement Iroda Kft. a magyar építőipari piac meghatározó szereplője. Több mint két évtizedes tervezői, beruházás-lebonyolítói tapasztalatunk és partnereink elégedettsége a garancia munkánk minőségére.',
        'about-p3': 'Megrendelőközpontú vállalatként és a piac alapos ismeretének birtokában iránymutatással, szaktanácsadással és megbízható napi munkánkkal messzemenően képviselni tudjuk a megrendelői érdekeket.',
        'header-services': 'Szolgáltatásaink',
        'service-1': 'Beruházáslebonyolítás',
        'service-1-p': 'Az előkészítéstől a kulcsrakész átadásig tartó valamennyi mérnöki feladatot magába foglalja ez a szolgáltatásunk, beleértve közreműködést a kivitelező kiválasztásában. A több 100 projektben szerzett tapasztalatunk a garancia arra, hogy a projektje a lehető legjobb kezekben van nálunk.',
        'service-2': 'Tervezés',
        'service-2-p': 'Irodánk fő profilja az építőipari generáltervezés. Tervezési tevékenységünk kiterjed a kereskedelmi-, iroda-, raktár-, ipari-, lakó- és középületek tervezésére, a vázlattervtől a kiviteli részlettervekig, beleértve látványtervezést, belsőépítészeti tervezést, valamint a hatósági ügyintézés, engedélyeztetés lebonyolítását is.',
        'service-3': 'Főépítésvezetés',
        'service-3-p': 'E szolgáltatásunk magába foglalja az kivitelezés során a szakági kivitelezők térbeni és időbeni irányítását, koordinációját, és ellenőrzését, valamint közreműködésünket a használatbavételi engedély megszerzésében. A költségek, a minőség és a határidők felügyeletével jelentős időbeli- és árelőnyt biztosítunk megrendelőink számára.',
        'service-4': 'Műszaki Ellenőrzés',
        'service-4-p': 'Generálkivitelező megbízása esetén műszaki ellenőri és tervezői művezetői tevékenységünk biztosítja a megrendelői elvárásoknak maximálisan megfelelő létesítmény megvalósítását. Más tervezőiroda megbízása esetén műszaki ellenőri tevékenységünk a tervellenőrzést is magába foglalhatja.',
        'service-5': 'Belsőépítészet',
        'service-5-p': 'Megrendelőink igényeinek megfelelően segítünk kiválasztani a megfelelő otthont, felújítjuk, berendezzük. A helységek arculatának kialakításakor a teljes stílusegység és színdinamikai harmónia elérése érdekében nem csak az enteriőröket, de a benn lévő bútorokat is mindig egyedileg egyénre szabva tervezzük.',
        'portfolio-1-type': 'Irodaközpont',
        'portfolio-1-service': 'Generáltervezés',
        'portfolio-2-type': 'lakópark',
        'portfolio-2-service': 'Főépítésvezetés',
        'portfolio-3-type': 'logisztikai és irodaközpont',
        'portfolio-3-service': 'Generáltervezés, főépítésvezetés',
        'portfolio-4-type': 'Bevásárlóközpont',
        'portfolio-4-service': 'Generáltervezés, műszaki ellenőrzés',
        'portfolio-5-type': 'Sopronbánfalva',
        'portfolio-5-service': 'Projectvezetés (Felújítás)',
        'portfolio-6-type': 'Szálloda',
        'portfolio-6-service': 'Generáltervezés',
        'portfolio-7-type': 'lakópark',
        'portfolio-7-service': 'Tervezés, látványtervezés',
        'portfolio-8-type': 'Iroda-Lakóépület',
        'portfolio-8-service': 'Generáltervezés',
        'portfolio-9-type': 'Sopronbánfalva',
        'portfolio-9-service': 'Építészeti tervezés',
        'portfolio-10-type': 'Gyárközpont',
        'portfolio-10-service': 'Főépítésvezetés, projectvezetés',
        'portfolio-11-type': 'Gyár',
        'portfolio-11-service': 'Főépítésvezetés',
        'portfolio-12-type': 'Logisztikai Központ',
        'portfolio-12-service': 'Generáltervezés, főépítésvezetés',
        'portfolio-13-type': 'Mobil Petrol Irodaház/töltőállomás',
        'portfolio-13-service': 'Tervezés, látványtervezés',
        'portfolio-14-type': 'Kecskemét',
        'portfolio-14-service': 'Tervezés, látványtervezés',
        'portfolio-15-type': '',
        'portfolio-15-service': ''
    },
    'de': {
        nivo: 'Architekturpreis 2017: Érd DM HQ',
        navAbout:  'Unternehmen',
        navServices: 'Leistungen',
        navPortfolio: 'Referenzen',
        navContacts: 'Kontakt',
        'txt-intro': 'Projektentwicklung - Generalplanung - Oberbauleitung - Baukontrolle',
        'header-about-us': 'Unternehmen',
        'about-p1': 'Die Firma Intermanagement Iroda Kft. wurde im Jahre 1993 gegründet und bietet für ihre internationale Kundenkreis umfangreiche, komplexe Ingenieurleistungen, die den gesamten Lebenszyklus des Hochbauprojekts umfassen, von der Investitionsidee bis hin zur schlüsselfertigen Abnahme.',
        'about-p2': 'Die Firma Intermanagement Iroda Kft. ist ein determinierender Teilnehmer des Baugewerbemarktes in Ungarn. Unsere über mehr als 22 Jahre gesammelte Erfahrung in Planung und Investitionsabwicklung sowie die Zufriedenheit unserer Partner ist die Garantie für die Qualität unserer Arbeit.',
        'about-p3': 'Als Unternehmen, wo der Auftraggeber im Mittelpunkt steht, durch die gründliche Kenntnis des Marktes können wir die Richtung weisen, mit Fachratschlägen sowie mit unserer zuverlässigen täglichen Arbeit sind wir imstande die Interessen des Auftraggebers weitgehend zu vertreten.',
        'header-services': 'Leistungen',
        'service-1': 'Projektentwicklung',
        'service-1-p': 'Diese Dienstleitung beinhaltet sämtliche Ingenieurleistungen von der Vorbereitung bis zur schlüsselfertigen Abnahme, einschließlich der Mitwirkung an der Auswahl des Ausführers. Unsere Erfahrungen, die wir im Zuge von mehreren 100 Projekten gesammelt haben, garantieren, dass Ihr Projekt bei uns in besten Händen ist.',
        'service-2': 'Planung',
        'service-2-p': 'Das Hauptprofil unserer Firma ist die Generalplanung im Baugewerbe. Unsere Planungstätigkeit umfasst die Planung von Handels-, Büro-, Lager-, Industrie-, Wohn- und öffentlichen Gebäuden, von der Entwurfsplanung bis hin zur Detailplanung, einschließlich der Visualisierung, Innenarchitekturplanung sowie der Behörden- und Genehmigungsabwicklung.',
        'service-3': 'Oberbauleitung',
        'service-3-p': 'Diese Dienstleistung beinhaltet im Zuge der Ausführung die räumliche und zeitliche Steuerung, Koordinierung und Kontrolle der einzelnen Ausführer sowie unsere Mitwirkung an der Einholung der Inbetriebnahmegenehmigung. Durch die Überwachung der Kosten, Qualität und Termine bieten wir einen wesentlichen Zeit- und Preisvorteil für unsere Auftraggeber.',
        'service-4': 'Baukontrolle',
        'service-4-p': 'Bei Beauftragung eines Generalunternehmers sichern wir durch technische Kontrolle und durch Objektüberwachung als Planer die sachgemäße Errichtung der Bauanlage, die den Bauherrenwünschen maximal entspricht. Bei Beauftragung eines anderen Planungsbüros beinhaltet unsere technische Kontrolle auch die Planprüfung.',
        'service-5': 'Innenarchitektur',
        'service-5-p': '',
        'portfolio-1-type': 'Bürozentrale',
        'portfolio-1-service': 'Generalplanung',
        'portfolio-2-type': 'Wohnpark',
        'portfolio-2-service': 'Oberbauleitung',
        'portfolio-3-type': 'Logistik- und Bürozentrale',
        'portfolio-3-service': 'Generalplanung, Oberbauleitung',
        'portfolio-4-type': 'Einkaufszentrum',
        'portfolio-4-service': 'Generalplanung, Baukontrolle',
        'portfolio-5-type': 'Sopronbánfalva',
        'portfolio-5-service': 'Projektentwicklung (Restaurierung)',
        'portfolio-6-type': 'Hotel',
        'portfolio-6-service': 'Generalplanung',
        'portfolio-7-type': 'Wohnpark',
        'portfolio-7-service': 'Planung, Visualisierung',
        'portfolio-8-type': 'Büro- und Wohngebäude',
        'portfolio-8-service': 'Generalplanung',
        'portfolio-9-type': 'Sopronbánfalva',
        'portfolio-9-service': 'Architekturdesign',
        'portfolio-10-type': 'Werkszentrum',
        'portfolio-10-service': 'Projektentwicklung, Oberbauleitung',
        'portfolio-11-type': 'Fabrik',
        'portfolio-11-service': 'Oberbauleitung',
        'portfolio-12-type': 'Logistikzentrale',
        'portfolio-12-service': 'Generalplanung, Oberbauleitung',
        'portfolio-13-type': 'Mobil Petrol Bürogebäude/Tankstelle',
        'portfolio-13-service': 'Planung, Visualisierung',
        'portfolio-14-type': 'Kecskemét',
        'portfolio-14-service': 'Planung, Visualisierung',
        'portfolio-15-type': ' ',
        'portfolio-15-service': ' '
        
    },
    'en': {
        nivo: 'Architectural Award 2017: Érd DM HQ',
        navAbout:  'About us',
        navServices: 'Services',
        navPortfolio: 'References',
        navContacts: 'Contacts',
        'txt-intro': 'Beruházáslebonyolítás - Generáltervezés - Főépítésvezetés - Műszaki Ellenőrzés',
        'header-about-us': 'About us',
        'about-p1': '',
        'about-p2': '',
        'about-p3': '',
        'header-services': 'Szolgáltatásaink',
        'service-1': '',
        'service-1-p': '',
        'service-2': '',
        'service-2-p': '',
        'service-3': '',
        'service-3-p': '',
        'service-4': '',
        'service-4-p': '',
        'service-5': '',
        'service-5-p': '',
        'portfolio-1-type': '',
        'portfolio-1-service': '',
        'portfolio-2-type': '',
        'portfolio-2-service': '',
        'portfolio-3-type': '',
        'portfolio-3-service': '',
        'portfolio-4-type': '',
        'portfolio-4-service': '',
        'portfolio-5-type': '',
        'portfolio-5-service': '',
        'portfolio-6-type': '',
        'portfolio-6-service': '',
        'portfolio-7-type': '',
        'portfolio-7-service': '',
        'portfolio-8-type': '',
        'portfolio-8-service': '',
        'portfolio-9-type': '',
        'portfolio-9-service': '',
        'portfolio-10-type': '',
        'portfolio-10-service': '',
        'portfolio-11-type': '',
        'portfolio-11-service': '',
        'portfolio-12-type': '',
        'portfolio-12-service': '',
        'portfolio-13-type': '',
        'portfolio-13-service': '',
        'portfolio-14-type': '',
        'portfolio-14-service': '',
        'portfolio-15-type': '',
        'portfolio-15-service': ''
    }
}
export { translations as default}