import translations from './translation'

const initStorage = () => {
    if (localStorage.getItem('lang') === null) {
        localStorage.setItem('lang', 'hu')
        document.querySelector('html').lang = 'hu'
      }
}



const translateDOM = () => {
    let elements = document.querySelectorAll('.lang')
    for (let i = 0; i < elements.length; i++) {
        elements[i].textContent = translations[localStorage.getItem('lang')][elements[i].getAttribute('id')]
    }
}

// Listen to click event on HU button
document.querySelector('#translate-hungarian').addEventListener('click', function(e) {
    localStorage.setItem('lang', e.target.innerText.toLowerCase())
    translateDOM()
    document.querySelector('html').lang = e.target.innerText.toLowerCase()
})

// Listen to click event on DE button
document.querySelector('#translate-german').addEventListener('click', function(e) {
    localStorage.setItem('lang', e.target.innerText.toLowerCase())
    translateDOM()
    document.querySelector('html').lang = e.target.innerText.toLowerCase()
})


// Listen to changes in lang local storage attribute
window.addEventListener('storage', function(e) {
    debugger
    if (e.key === 'lang') {
        //lang = JSON.parse(e.newValue)
        debugger
        translateDOM()
    }
})

export { initStorage }